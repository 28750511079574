"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
window.addEventListener('keydown', function (e) {
    if (e.keyCode == 32 && e.target == document.body) {
        e.preventDefault();
    }
});
class DotsCtrl {
    constructor($scope, $timeout, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.endTtl = 1 * 1000;
        this.sizes = {
            '3x3': {
                title: '3×3',
                size: 3,
                defaultTiles: 2,
                tiles: [2, 3, 4, 5, 6],
            },
            '4x4': {
                title: '4×4',
                size: 4,
                defaultTiles: 4,
                tiles: [4, 6, 8, 9, 10],
            },
            '5x5': {
                title: '5×5',
                size: 5,
                defaultTiles: 8,
                tiles: [8, 10, 12, 13, 14],
            },
            '6x6': {
                title: '6×6',
                size: 6,
                defaultTiles: 10,
                tiles: [10, 15, 20, 22, 25],
            },
            '7x7': {
                title: '7×7',
                size: 7,
                defaultTiles: 15,
                tiles: [15, 20, 25, 27, 30],
            },
            '8x8': {
                title: '8×8',
                size: 8,
                defaultTiles: 20,
                tiles: [20, 25, 30, 32, 35],
            },
        };
        this.size = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || '{"size": "3x3"}').size;
        this.tiles = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || `{"tiles": ${this.sizes[this.size].defaultTiles}}`).tiles;
        this.found = 0;
        this.status = 'game';
        this.game$ = new rxjs_1.Subject();
        this.clickNumber$ = new rxjs_1.Subject();
        this.$scope.$watch(() => {
            return {
                size: this.size,
                tiles: this.tiles
            };
        }, (data) => {
            localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data));
        }, true);
        this.$scope.$watch('status', () => {
            console.log(this.status);
        }, true);
    }
    $onInit() {
        this.game$.pipe((0, operators_1.startWith)({
            size: this.size,
            tiles: this.tiles,
            found: this.found,
            grid: this.grid
        }), (0, operators_1.map)((initial) => {
            console.log('initial', initial);
            const size = this.sizes[this.size];
            document.documentElement.style.setProperty('--grid-size', size.size.toString());
            // document.documentElement.style.setProperty('--grid-size', size.size.toString());
            if (initial.grid != undefined) {
                this.status = 'game';
                return initial;
            }
            else {
                this.status = 'ready';
                const z = Array.from({ length: size.size * size.size }, (_, index) => {
                    return {
                        x: index - size.size * Math.floor(index / size.size),
                        y: Math.floor(index / size.size),
                    };
                });
                (0, utils_1.shuffle)(z);
                let points = {};
                for (let item of z.splice(0, initial.tiles).map((item) => {
                    return item;
                })) {
                    points[`${item.x}-${item.y}`] = Object.assign(Object.assign({}, item), { value: true, visible: true, valid: undefined });
                }
                return Object.assign(Object.assign({}, initial), { grid: Array.from({ length: size.size }, (_, x) => {
                        return Array.from({ length: size.size }, (_, y) => {
                            if (points.hasOwnProperty(`${x}-${y}`)) {
                                return Object.assign({}, points[`${x}-${y}`]);
                            }
                            else {
                                return {
                                    x: x,
                                    y: y,
                                    value: false,
                                    visible: true,
                                    valid: undefined,
                                };
                            }
                        });
                    }) });
            }
        }), (0, operators_1.switchMap)((gameData) => {
            this.grid = gameData.grid;
            return this.clickNumber$.pipe((0, operators_1.filter)(() => {
                return this.status == 'game';
            }), (0, operators_1.map)((data, index) => {
                data.col.valid = data.col.value && (data.col.valid == undefined);
                this.found += 1;
                if (this.found == (gameData.tiles)) {
                    // for click on grid
                    data.event.stopPropagation();
                }
                if ((data.col.valid == false) && !this.ConfigService.cookieSettings.allow_errors) {
                    data.event.stopPropagation();
                    throw new Error(`Valid token not returned ${gameData.number} != ${data.col.value}`);
                }
                return index;
            }), (0, operators_1.catchError)((err) => {
                console.log(err);
                this.status = 'retry';
                return (0, rxjs_1.of)(null);
            }), 
            // bufferCount(gameData.tiles),
            // take(gameData.tiles),
            (0, operators_1.takeWhile)(() => {
                return this.found < this.tiles;
            }), (0, operators_1.toArray)(), (0, operators_1.mapTo)(gameData));
        }), (0, operators_1.tap)((gameData) => {
            console.log('finish', this.status);
            if (this.status != 'retry') {
                let hasError = false;
                this.grid.forEach((row) => row.forEach((col) => {
                    if (col.value && !col.valid) {
                        hasError = true;
                    }
                }));
                this.status = hasError ? 'retry' : 'end';
            }
            if (this.status != 'retry') {
                this.status = 'end';
                this.$timeout(() => {
                    if (this.status == 'end')
                        this.newGame(gameData.size, gameData.tiles);
                }, this.endTtl);
            }
        })).subscribe();
        (0, rxjs_1.fromEvent)(document, 'keydown').pipe((0, operators_1.filter)((event) => {
            return (event.keyCode == 32) || (event.keyCode == 13);
        }), (0, operators_1.filter)(() => {
            return ['end', 'game'].indexOf(this.status) == -1;
        }), (0, operators_1.tap)(() => {
            this.$scope.$apply(() => {
                this.start();
            });
        })).subscribe();
    }
    newGame(size, tiles) {
        this.size = size;
        this.tiles = (this.sizes[size].tiles.indexOf(tiles) > -1) ? tiles : this.sizes[size].defaultTiles;
        this.found = 0;
        this.game$.next({
            size: this.size,
            tiles: this.tiles,
            found: this.found,
            grid: undefined
        });
    }
    clickNumber(col, event) {
        if (this.ConfigService.cookieSettings.allow_errors && (col.valid != undefined)) {
            col.valid = undefined;
            this.found -= 1;
        }
        else {
            if (col.valid == undefined) {
                this.clickNumber$.next({
                    col: col,
                    event: event
                });
            }
        }
    }
    start() {
        var _a;
        if (['retry', 'end'].indexOf(this.status) > -1) {
            this.newGame(this.size, this.tiles);
        }
        else {
            (_a = this.grid) === null || _a === void 0 ? void 0 : _a.forEach((row) => row.forEach((col) => {
                col.visible = false;
            }));
            this.status = 'game';
        }
    }
}
DotsCtrl.$inject = ['$scope', '$timeout', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameDots', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: DotsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('dots/');
    }]);
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('dots/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            allow_errors: true,
        });
    }]);
